.book-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 24px;
  padding: 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.book-link {
  text-decoration: none;
  color: inherit;
}

.book-card {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 16px;
  text-align: center;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.book-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.book-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
}

.book-info {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100px;
}

.book-title {
  margin: 12px 0 8px 0;
  font-size: 1em;
  color: #2d3436;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.35em;
  height: 2.7em;
  margin-bottom: 4px;
}

.book-author {
  margin: 0;
  font-size: 0.85em;
  color: #636e72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.load-more-button {
  display: block;
  margin: 24px auto;
  padding: 12px 24px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.load-more-button:hover {
  background-color: #2980b9;
  transform: translateY(-1px);
}

.load-more-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  transform: none;
}

h2 {
  font-size: 2em;
  color: #2d3436;
  margin: 32px 0 24px 0;
  font-weight: 600;
}

.book-card.skeleton {
  animation: pulse 1.5s infinite;
}

.skeleton-image {
  background-color: #eee;
  width: 100%;
  height: 200px;
  margin-bottom: 12px;
}

.skeleton-title {
  background-color: #eee;
  height: 1em;
  width: 80%;
  margin: 8px auto;
}

.skeleton-author {
  background-color: #eee;
  height: 0.8em;
  width: 60%;
  margin: 8px auto;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
