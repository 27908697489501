/* Add a specific class prefix to scope all styles */
.statusPage {
  /* Move body/html resets to the container class */
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.statusPage .pageContainer {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: 100dvh;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.statusPage .logContainer {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0;
  border-radius: 4px;
  background-color: #f8f9fa;
  box-sizing: border-box;
}

.statusPage .heading {
  margin: 0;
  padding: 5px 0;
  flex-shrink: 0; /* Prevent headers from shrinking */
}

.statusPage .status {
  flex-shrink: 0; /* Prevent status from shrinking */
}

.statusPage .logEntry {
  padding: 5px;
  border-bottom: 1px solid #eee;
  font-family: monospace;
  word-wrap: break-word;
}

.statusPage .connected {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.statusPage .error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
