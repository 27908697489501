/* Styles for BookDetail component */
.tabs {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.tabs button {
  flex-shrink: 0;
  padding: 10px 20px;
  border: 2px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  white-space: nowrap;
}

.tabs button.active {
  background-color: #21a1f1;
  border-color: #21a1f1;
  color: white;
}

.tabs button:hover {
  background-color: #1e90ff;
  border-color: #1e90ff;
  color: white;
}

.summary-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summary-content h2 {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}

.summary-content p {
  margin-top: 10px;
  line-height: 1.6;
  color: #555;
}

.download-link {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.download-link:hover {
  background-color: #007bff;
  color: #fff;
}

.download-link i {
  margin-right: 5px;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

h3 {
  font-size: 1.2em;
  font-weight: normal;
  color: #555;
  margin-top: 0;
  margin-bottom: 1em;
}

.book-detail-container {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 0 2rem;
  background: white;
}

.breadcrumb {
  margin-bottom: 1.5rem;
  color: #666;
}

.breadcrumb a {
  color: #0066ff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.book-header {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
}

.book-info {
  flex: 1;
}

.book-info h1 {
  font-size: 1.75rem;
  line-height: 1.2;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.book-info .subtitle {
  font-size: 1.25rem;
  color: #666;
  font-weight: normal;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.book-info .author {
  font-size: 1rem;
  color: #666;
  font-weight: normal;
}

.book-cover {
  width: 140px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .book-detail-container {
    margin: 0; /* Remove horizontal margins on mobile */
    padding: 0px; /* Further reduce padding on mobile */
  }

  .book-header {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .book-cover {
    margin: 0;
  }

  .book-info {
    text-align: center;
  }

  .book-info h1 {
    font-size: 1.75rem;
  }
}

.table-of-contents {
  margin: 1rem 0;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.toc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  user-select: none;
}

.toc-header:hover {
  background: #f0f0f0;
}

.toc-header h2 {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
  border: none;
}

.toc-header i {
  color: #666;
  transition: transform 0.2s ease;
}

.table-of-contents ul {
  list-style: none;
  padding: 0 1.5rem;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.table-of-contents ul.open {
  max-height: 2000px; /* Adjust this value based on your needs */
  padding-bottom: 1rem;
}

.table-of-contents li {
  margin: 0;
  line-height: 1.4; /* Reduced line height */
}

.table-of-contents a {
  color: #0066ff;
  text-decoration: none;
  transition: color 0.2s;
  display: block;
  padding: 0.3rem 0; /* Reduced padding */
  font-size: 0.95rem; /* Slightly smaller font size */
}

.table-of-contents a:hover {
  color: #004dc0;
}

/* Optional: Add a subtle animation for the chevron */
.toc-header i {
  transition: transform 0.3s ease;
}

.toc-header i.fa-chevron-up {
  transform: rotate(180deg);
}

/* Language Navigation Tabs - More subtle and refined */
.main-tabs {
  display: flex;
  margin: 1.5rem 0 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
}

.main-tabs button {
  padding: 8px 24px;
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  color: #666;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.main-tabs button.active {
  color: #0066ff;
  border-bottom: 2px solid #0066ff;
  background: transparent;
}

.main-tabs button:hover:not(.active) {
  color: #40a9ff;
  border-bottom: 2px solid #40a9ff;
  background: transparent;
}

/* Download Section - More compact and elegant */
.download-section {
  display: inline-flex; /* Change to inline-flex to prevent full width */
  gap: 12px;
  margin: 1.5rem 0;
  padding: 0; /* Remove padding */
  background: transparent; /* Remove background */
}

.download-link {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 0.9rem;
  color: #0066ff;
  background: transparent;
  border: 1px solid #0066ff;
  border-radius: 4px;
  transition: all 0.2s ease;
  text-decoration: none;
  font-weight: 500;
}

.download-link:hover {
  background-color: #0066ff;
  color: white;
}

.download-link i {
  margin-right: 8px;
  font-size: 0.9rem;
}

/* Content sections - Reduced spacing */
article.mixed-language-content,
article.single-language-content {
  @media print {
    display: flow-root; /* Creates a new block formatting context */
    page-break-before: avoid;
    page-break-after: avoid;
    break-before: avoid;
    break-after: avoid;
  }
}

section {
  padding: 0.5rem 0;
  background: white;

  /* Print-specific styles */
  @media print {
    margin: 0;
    padding: 1rem;
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
    break-before: avoid;
    break-after: avoid;
    break-inside: avoid;
    background: none;
  }
}

section h2 {
  font-size: 1.75rem;
  color: #1a1a1a;
  margin-bottom: 1rem; /* Reduced from 1.5rem */
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #eaeaea;

  @media print {
    border-bottom: none;
    padding-bottom: 0;
  }
}

section p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #333;
  margin: 0.75rem 0;
  text-align: left;
  hyphens: none;
  /* Add better CJK font support */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Microsoft YaHei",
    "PingFang SC", "STXihei", "Source Han Sans SC", "Noto Sans CJK SC",
    "WenQuanYi Micro Hei", sans-serif;

  /* Print-specific styles */
  @media print {
    /* Ensure CJK text prints properly */
    font-family: "Microsoft YaHei", "PingFang SC", "STXihei",
      "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", serif;
    text-align: left;
    word-break: normal;
    overflow-wrap: anywhere;
    page-break-inside: auto;
    break-inside: auto;
    orphans: 2;
    widows: 2;
  }
}

/* Secondary language text - Softer colors and reduced spacing */
.secondary-language {
  color: #444;
  background: #f8f9fa;
  padding: 0.75rem 1rem;
  border-left: 3px solid #8c8c8c;
  margin: 0.75rem 0;
  border-radius: 0 4px 4px 0;

  /* Print-specific styles */
  @media print {
    background: transparent;
    border-left: 2px solid #8c8c8c;
    padding-left: 0.75rem;
    margin: 0.5rem 0;
    page-break-inside: avoid;
    break-inside: avoid;

    /* Ensure Chinese characters print properly */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Microsoft YaHei", "PingFang SC", "STXihei", "Helvetica Neue", Arial,
      sans-serif;
  }
}

/* Responsive improvements */
@media (max-width: 768px) {
  .book-detail-container {
    padding: 0; /* Remove all padding */
    margin: 0; /* Remove all margins */
    max-width: 100%; /* Ensure full width */
  }

  /* Header section - keep minimal padding */
  .breadcrumb {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    padding: 0 0.5rem;
  }

  .book-header {
    margin-bottom: 0.75rem;
    gap: 0.75rem;
    padding: 0 0.5rem;
  }

  /* Language tabs - extend full width */
  .main-tabs {
    margin: 0.5rem 0;
    padding: 0 0.5rem;
  }

  /* Download section */
  .download-section {
    margin: 0.2rem;
  }

  .download-section .download-link {
    font-size: 0.8rem;
    padding: 0.3rem 0.8rem;
  }

  /* Table of contents */
  .table-of-contents {
    margin: 0.5rem 0;
    border-radius: 0; /* Remove border radius for full-width look */
    border-left: none;
    border-right: none;
  }

  .table-of-contents .toc-header {
    padding: 0.8rem 0.5rem;
  }

  .table-of-contents ul {
    padding: 0 0.5rem;
  }

  .table-of-contents li {
    line-height: 1;
  }

  .table-of-contents h2 {
    font-size: 0.9rem;
  }

  /* Content sections - with borders and more compact titles */
  section {
    margin: 0.5rem -0.5rem; /* Remove margin between sections */
    padding: 0.5rem 0.5rem;
  }

  section h2 {
    font-size: 1.1rem; /* Reduced from 1.3rem */
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eaeaea;
    color: #333; /* Slightly softer color */
    font-weight: 600;
  }

  section p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.5rem 0;
    padding: 0;
    text-align: left; /* Change from justify to left for better mobile readability */
    hyphens: none; /* Remove hyphenation on mobile */
  }

  .secondary-language {
    margin: 0.5rem 0;
    padding: 0.5rem 0.75rem;
    border-radius: 0;
  }
}

/* Add print-specific styles for the entire container */
@media print {
  /* Hide only navigation elements */
  .breadcrumb,
  .main-tabs,
  .download-section,
  .table-of-contents {
    display: none;
  }

  /* Container adjustments */
  .book-detail-container {
    margin: 0;
    padding: 0;
    max-width: none;
  }

  /* Ensure CJK text prints properly */
  section p {
    font-family: "Microsoft YaHei", "PingFang SC", "STXihei",
      "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", serif;
    text-align: left;
    word-break: normal;
    overflow-wrap: anywhere;
  }

  /* Prevent blank spaces and ensure content flows */
  section {
    page-break-inside: auto; /* Allow breaks within sections if needed */
    break-inside: auto;
  }

  /* Keep paragraphs together when possible */
  p {
    orphans: 3;
    widows: 3;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

/* Book title and chapter headings */
h1,
h2 {
  page-break-after: avoid;
  page-break-inside: avoid;
  break-after: avoid;
  break-inside: avoid;

  /* Ensure Chinese characters display properly */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Microsoft YaHei",
    "PingFang SC", "Helvetica Neue", Arial, sans-serif;
}
